/*
Author: joel Ando Ngah-Wanneh
Date: 01/10/2023
Degree software engineering

*/

/******* Bootstrap Theming ********/

$primary: #8325bd;
$secondary: #f14d5d;
$light: #ecf4ff;
$dark: #120f2d;

$font-family-base: "Open Sans", sans-serif;

$headings-font-family: "Jost", sans-serif;

$body-bg: #ffffff;

$body-color: #6b6a75;

$headings-color: $dark;

$enable-responsive-font-sizes: true;

$border-radius: false;

@import "/node_modules/bootstrap/scss/bootstrap.scss";

/********** Custom CSS ************/
h1,
h2,
.font-weight-bold {
  font-weight: 700 !important;
}

h3,
h4,
.font-weight-semi-bold {
  font-weight: 600 !important;
}

h5,
h6,
.font-weight-medium {
  font-weight: 500 !important;
}

.btn {
  font-family: "Jost", sans-serif;
}

.btn-square {
  width: 36px;
  height: 36px;
}

.btn-sm-square {
  width: 28px;
  height: 28px;
}

.btn-lg-square {
  width: 46px;
  height: 46px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}

.btn-icon {
  width: 77px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 11;
}

.navbar-light .navbar-nav .nav-link {
  font-family: "Jost", sans-serif;
  padding: 30px 15px;
  font-size: 18px;
  font-weight: 500;
  color: $dark;
  outline: none;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: $primary;
}

@media (max-width: 991.98px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 10px 15px;
  }
}

.jumbotron {
  background: linear-gradient(rgba(40, 120, 235, 0.2), rgba(40, 120, 235, 0.2)),
    url(./images/header.jpg), no-repeat center center;
  background-size: cover;
}

// .jumbotron.page-header {
//     background: linear-gradient(rgba(40, 120, 235, 0.9), rgba(40, 120, 235, 0.9)), url(../images/page-header.jpg), no-repeat center center;
//     background-size: cover;
// }

.overlay-top::before,
.overlay-bottom::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 85px;
  left: 0;
  z-index: 1;
}

.overlay-top::before {
  top: 0;
  background: url(./images/overlay-top.png) top center no-repeat;
  background-size: contain;
}

.overlay-bottom::after {
  bottom: 0;
  background: url(./images/overlay-bottom.png) bottom center no-repeat;
  background-size: contain;
}

.bg-image {
  background: linear-gradient(
      rgba(40, 120, 235, 0.05),
      rgba(40, 120, 235, 0.05)
    ),
    url(./images/bg-image.jpg);
  background-attachment: fixed;
}

.section-title h6::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 0;
  bottom: 0;
  left: 0;
  border-top: 2px dashed $secondary;
}
.excellent {
  color: $secondary;
  font-weight: bold;
}

// .carousel-container {
//   width: 80%;
//   height: 100%;
//   margin-left: 10%;
//   margin-right: 10%;
// }

.courses-item img {
  position: relative;
  margin-top: 40px;
  transition: 0.5s;
}

.courses-list-item img {
  position: relative;
  transition: 0.5s;
}

.courses-item .courses-text,
.courses-list-item .courses-text {
  position: absolute;
  width: 100%;
  height: calc(100% + 86px);
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    to bottom,
    rgba(18, 15, 45, 0),
    rgba(18, 15, 45, 1)
  );
  transition: 0.5s;
  z-index: 1;
}

.courses-list-item .courses-text {
  height: 100%;
}

.courses-item:hover img {
  margin-top: 0px;
}

.courses-list-item:hover img {
  transform: scale(1.2);
}

.courses-item:hover .courses-text {
  height: calc(100% + 40px);
}

.team-carousel,
.related-carousel {
  position: absolute;
  width: 100%;
  height: 60px;
  top: calc(50% - 30px);
  left: 0;
  display: flex;
  justify-content: space-between;
  z-index: 1;
}

.admin-container {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-right: 10%;
}
.carousel-item {
  padding-right: 40px;
  margin-left: 10%;
}

.team-carousel {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: --primary;
  font-size: 22px;
  transition: 0.5s;
}

.testimonial-carousel img {
  width: 80px;
  height: 80px;
}

.testimonial-carousel {
  position: absolute;
  width: 120px;
  height: 60px;
  top: 0;
  right: 0;
  display: flex;
  z-index: 1;
}

.testimonial-carousel {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: --primary;
  font-size: 22px;
  transition: 0.5s;
}

.formDesign {
  border-top: 0;
  border-left: 0;
  border-right: 0;
}

// .testimonial-carousel .owl- {
//   background: --secondary;
// }

// .testimonial-carousel .owl-nav .owl-prev:hover {
//   background: --primary;
// }
.card-registration .select-input.form-control[readonly]:not([disabled]) {
  font-size: 1rem;
  line-height: 2.15;
  padding-left: 0.75em;
  padding-right: 0.75em;
}
.card-registration .select-arrow {
  top: 13px;
}

.myPointer {
  cursor: pointer;
}

.scroll-top-btn {
  position: fixed;
  background: $primary;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  z-index: 1000;
  font-size: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.scroll-top-btn.visible {
  opacity: 0.5;
}
.scroll-top-btn:hover {
  opacity: 1;
}

.scroll-top-btn.invisible {
  opacity: 0;
}
.sidebar-btn {
  margin-right: 20px;
  border-radius: 5px;
}
@media (max-width: 767px) {
  .sidebar-btn {
    margin-left: 40%;
  }
}

.spinner-button {
  position: relative;
}

.spinner {
  border: 4px solid rgba(68, 117, 239, 0.2); /* Set the border color */
  border-top: 4px solid #4475ef; /* Set the spinner color */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none; /* Initially hide the spinner */
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
